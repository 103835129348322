"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IframeController = void 0;
var IframeController = /** @class */ (function () {
    function IframeController(target, src) {
        var iframe;
        var element = typeof target === 'string' ? document.querySelector(target) : target;
        if (element === null) {
            throw new Error('IFrame or mount element not found by selector ' + target);
        }
        if (element instanceof HTMLIFrameElement) {
            iframe = element;
        }
        else {
            iframe = document.createElement('iframe');
            element.appendChild(iframe);
        }
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('scrolling', 'no');
        iframe.setAttribute('allowfullscreen', 'true');
        iframe.setAttribute('allow', 'allowfullscreen');
        iframe.setAttribute('crossorigin', 'anonymous');
        iframe.setAttribute('src', src);
        this.iframe = iframe;
    }
    IframeController.prototype.getIframe = function () {
        return this.iframe;
    };
    IframeController.prototype.setStyles = function (styles) {
        var iframe = this.getIframe();
        for (var _i = 0, _a = Object.entries(styles); _i < _a.length; _i++) {
            var _b = _a[_i], styleKey = _b[0], value = _b[1];
            // @ts-ignored
            iframe.style[styleKey] = value;
        }
    };
    return IframeController;
}());
exports.IframeController = IframeController;
