"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IframeEventChannelController = void 0;
var IframeEventChannelController = /** @class */ (function () {
    function IframeEventChannelController(iFrameController, domain) {
        if (domain === void 0) { domain = '*'; }
        var _this = this;
        this.channel = new MessageChannel();
        this.eventIndex = 0;
        this.handlers = {};
        var iframe = iFrameController.getIframe();
        if (iframe.hasAttribute('data-event-channel-setup')) {
            throw new Error('An embed viewer instance already initialized on the iframe!');
        }
        else {
            iframe.setAttribute('data-event-channel-setup', 'true');
        }
        this.channelSetupPromise = (function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) {
                            iframe.addEventListener('load', function () {
                                var _a;
                                _this.channel.port1.start();
                                var port1Handler = function (e) {
                                    var type = e.data[0];
                                    if (type === 'channel-ready') {
                                        e.preventDefault();
                                        _this.channel.port1.removeEventListener('message', port1Handler);
                                        _this.channel.port1.addEventListener('message', _this.eventDispatcher.bind(_this));
                                        resolve(undefined);
                                    }
                                };
                                _this.channel.port1.addEventListener('message', port1Handler);
                                (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(['setup-channel', { port: _this.channel.port2 }], domain || '*', [_this.channel.port2]);
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }
    IframeEventChannelController.prototype.eventDispatcher = function (e) {
        var _a = e.data || [], type = _a[0], eventName = _a[1], payload = _a[2];
        if (type === 'event' && eventName && this.handlers[eventName]) {
            this.handlers[eventName].forEach(function (handler) { return handler(payload); });
        }
    };
    IframeEventChannelController.prototype.addEventListener = function (event, callback) {
        this.handlers[event] = this.handlers[event] || [];
        if (this.handlers[event].includes(callback))
            return;
        this.handlers[event].push(callback);
    };
    IframeEventChannelController.prototype.removeEventListener = function (event, callback) {
        if (!this.handlers[event])
            return;
        var index = this.handlers[event].findIndex(function (fn) { return fn === callback; });
        this.handlers[event].splice(index, 1);
    };
    IframeEventChannelController.prototype.emit = function (event, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var replyEvent;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.channelSetupPromise];
                    case 1:
                        _a.sent();
                        replyEvent = "xmind-embed-viewer#".concat(this.eventIndex++);
                        return [4 /*yield*/, new Promise(function (resolve) {
                                var handler = function (e) {
                                    var _a = e.data, message = _a[0], payload = _a[1];
                                    if (message === replyEvent) {
                                        _this.channel.port1.removeEventListener('message', handler);
                                        resolve(payload);
                                    }
                                };
                                _this.channel.port1.addEventListener('message', handler);
                                _this.channel.port1.postMessage([event, payload, replyEvent]);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return IframeEventChannelController;
}());
exports.IframeEventChannelController = IframeEventChannelController;
