"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XMindEmbedViewer = void 0;
var channel_controller_1 = require("./channel-controller");
var iframe_controller_1 = require("./iframe-controller");
var XMindEmbedViewer = /** @class */ (function () {
    /**
     * Initialize a iframe element from a div/iframe html element.
     */
    function XMindEmbedViewer(args) {
        var _this = this;
        this.internalState = {
            sheets: [],
            zoomScale: 100,
            currentSheetId: ''
        };
        var file = args.file, el = args.el, _a = args.region, region = _a === void 0 ? 'global' : _a, _b = args.styles, styles = _b === void 0 ? {
            'height': '350px',
            'width': '750px',
        } : _b, isPitchModeDisabled = args.isPitchModeDisabled;
        var domain = region === 'cn' ? 'www.xmind.cn' : 'www.xmind.app';
        var iframeController = new iframe_controller_1.IframeController(el, "https://".concat(domain, "/embed-viewer").concat(isPitchModeDisabled ? '?pitch-mode=disabled' : ''));
        var iframeEventChannelController = new channel_controller_1.IframeEventChannelController(iframeController, "https://".concat(domain));
        this.iframeController = iframeController;
        this.iframeEventChannelController = iframeEventChannelController;
        this.region = region;
        iframeEventChannelController.addEventListener('sheet-switch', function (payload) { return _this.internalState.currentSheetId = payload; });
        iframeEventChannelController.addEventListener('zoom-change', function (payload) { return _this.internalState.zoomScale = payload; });
        iframeEventChannelController.addEventListener('sheets-load', function (payload) { return _this.internalState.sheets = payload; });
        this.iframeController.setStyles(styles);
        if (file) {
            this.load(file);
        }
    }
    /**
     * Add event listener for embed viewer.
     *
     * Available events:
     * - map-ready
     * - zoom-change
     * - sheet-switch
     * - sheets-load
     *
     */
    XMindEmbedViewer.prototype.addEventListener = function (event, callback) {
        this.iframeEventChannelController.addEventListener(event, callback);
    };
    XMindEmbedViewer.prototype.removeEventListener = function (event, callback) {
        this.iframeEventChannelController.removeEventListener(event, callback);
    };
    /**
     * Update styles for created iframe element.
     */
    XMindEmbedViewer.prototype.setStyles = function (styles) {
        this.iframeController.setStyles(styles);
    };
    /**
     * Load a file for embed viewer after iframe ready.
     */
    XMindEmbedViewer.prototype.load = function (file) {
        this.iframeEventChannelController.emit('open-file', file);
    };
    XMindEmbedViewer.prototype.setZoomScale = function (zoomScale) {
        this.iframeEventChannelController.emit('zoom', zoomScale);
    };
    XMindEmbedViewer.prototype.setFitMap = function () {
        this.iframeEventChannelController.emit('fit-map');
    };
    XMindEmbedViewer.prototype.switchSheet = function (sheetId) {
        this.iframeEventChannelController.emit('switch-sheet', sheetId);
    };
    Object.defineProperty(XMindEmbedViewer.prototype, "zoom", {
        get: function () {
            return this.internalState.zoomScale;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(XMindEmbedViewer.prototype, "sheets", {
        get: function () {
            return JSON.parse(JSON.stringify(this.internalState.sheets));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(XMindEmbedViewer.prototype, "currentSheetId", {
        get: function () {
            return this.internalState.currentSheetId;
        },
        enumerable: false,
        configurable: true
    });
    return XMindEmbedViewer;
}());
exports.XMindEmbedViewer = XMindEmbedViewer;
